<template>
  <b-row :class="'asljkdbnaskjbdjaks'">
    <b-col cols="12">
      <h2>Mensajería</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />

    <div class="row col-12">
      <div class="col-3">
        <b-form-group
          label="Buscar departamento"
          class="searchDepartament"
        >
          <b-form-input
            v-model="message.Searchdepartament"
            placeholder="Buscar departamento"
            type="text"
            @input="searchDepartamentFunction($event)"
          />
        </b-form-group>
        <div class="departamentList">
          <b-list-group
            v-for="item in sortedItems"
            :key="item.id"
            :body="item"
            :search-departmen="item.name"
            :class="'search-elements'"
            @click="selectDepartament(item)"
          >
            <b-list-group-item
              :id="'load_message' + item.id"
              :active="message.departament === item.id"
              href="#"
              class="d-flex justify-content-between align-items-center message-badgage-list"
            >
              {{ item.name }}
              <b-badge
                v-if="resolveBadge(item)"
                :id="'badge-number_' + item.id"
                variant="primary"
                pill
                class="badge-number"
              >
                <i
                  class="ri-message-line hp-text-color-dark-0"
                  style="font-size: 12px"
                />
              </b-badge>
              <span
                :id="'lest_message_' + item.id"
                class="last_message"
              >
                {{ item.last_message.slice(0, 20) + " ..." }}
              </span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <div class="col-9 bg-meesage">
        <div id="blur-bg-img" />
        <div class="meessage-show-box">
          <div class="meessage-show" />
        </div>
        <div
          v-if="message.departament != ''"
          class="massage-tools"
        >
          <b-col
            cols="12"
            class="message-tool-box"
          >
            <label
              for=""
            >Escribe un mensaje al departamento
              {{ message.departamentName || "" }}</label>
            <b-input-group class="mb-2">
              <b-form-input
                v-model="message.send"
                class="message-tool-box-input"
                type="text"
                @keydown.native="send_message"
              />
              <b-button
                variant="primary"
                class="btn-ghost"
                @click="send_message"
              >
                Enviar
              </b-button>
            </b-input-group>
          </b-col>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import datatable from '@/layouts/components/datatable/Datatable.vue'
import sweet from '@/logic/functions/sweetAlert'
import packages from '@/logic/functions/packages'
import departaments from '@/logic/functions/departaments'
import buildingsMessages from '@/logic/functions/buildingsMessages'
import { DateTime } from 'luxon'
import { IMaskComponent, IMaskDirective, useIMask } from 'vue-imask'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, // this
    BFormFile,
    BPagination,
    BListGroup,
    BListGroupItem,
    datatable,
    'imask-input': IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        { key: 'company', label: 'Empresa' },
        {
          key: 'department_uuid',
          label: 'Departamento',
          formatter: (value, key, item) => item.department_uuid.nro,
        },
        {
          key: 'created_at',
          label: 'Fecha de recepción',
          formatter: (value, key, item) => `${DateTime.fromISO(item.created_at).toFormat(
            'dd/MM/yyyy HH:mm ',
          )}hrs`,
        },
        { key: 'actions', label: 'Acciones' },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      loadLastDepartamentsMessages: [],
      loadDepartamentsData: [],
      checkLoadWholesData: [],
      existingUuid: [],
      messageLargeAmountMessage: [],
      chatConfig: {},
      userSendMessage: '',
      userSendMessageUuid: '',
      intervalMessage: null,
      message: {
        send: '',
        departament: '',
        departamentName: '',
        Searchdepartament: '',
      },
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    }
  },
  computed: {
    sortedItems() {
      return [...this.items].sort(
        (a, b) => b.last_message_created_at - a.last_message_created_at,
      )
    },
  },
  watch: {
    checkLoadWholesData(val) {
      if (
        this.checkLoadWholesData.includes('A')
        && this.checkLoadWholesData.includes('B')
      ) {
        this.items = []
        Object.entries(this.loadDepartamentsData).forEach(entry => {
          const [key, value] = entry

          if (this.existingUuid.length > 0) {
            if (!this.existingUuid.includes(value.id)) {
              this.items.push({
                id: value.id,
                name: value.name,
                last_message: '',
                last_message_created_at: value.last_message_created_at,
                last_message_read_at: value.last_message_read_at,
              })
            }
          } else if (this.existingUuid.length == 0) {
            this.items.push({
              id: value.id,
              name: value.name,
              last_message: '',
              last_message_created_at: value.last_message_created_at,
              last_message_read_at: value.last_message_read_at,
            })
          }
        })
      }
    },
  },
  beforeMount() {},
  mounted() {
    const user_data = JSON.parse(localStorage.getItem('home_app_user'))
    this.userSendMessage = `${user_data.user.name} ${user_data.user.last_name}`
    this.userSendMessageUuid = user_data.user.uuid

    this.loadLastMessages()
    this.loadDepartaments()
    this.intervalMessage = setInterval(() => {
      this.loadDepartaments()
      if (this.message.departament == '') return
      document.getElementById('blur-bg-img').classList.add('blur-bg-img')
      document.getElementsByClassName('meessage-show')[0].innerHTML = ''
      this.loadMessages(this.message.departament)
    }, 10000)
  },
  unmounted() {
    clearInterval(this.intervalMessage)
  },
  methods: {
    resolveBadge(item) {
      if (item.last_message_created_at && item.last_message_read_at == null) {
        return true
      }
      return false
    },
    scrollDown() {
      const scroll = document.getElementsByClassName('meessage-show')[0]
      scroll.scrollTop = scroll.scrollHeight
    },
    searchDepartamentFunction(data) {
      Object.entries(
        document.getElementsByClassName('search-elements'),
      ).forEach(entry => {
        const [key, value] = entry

        if (
          value.getAttribute('search-departmen').toLowerCase().trim()
            != data.toLowerCase().trim()
          && data != ''
        ) {
          value.style.display = 'none'
        } else if (
          value.getAttribute('search-departmen').toLowerCase().trim()
            == data.toLowerCase().trim()
          && data != ''
        ) {
          value.style.display = 'block'
        } else if (data == '') {
          value.style.display = 'block'
        }
      })
    },
    constructMessage(message, dir, uuid, sendByUser, time) {
      // dir = inside,outside
      const dirClass = dir == 'inside' ? 'meessage-inside' : 'meessage-outside'
      const sendByUsers = sendByUser || this.userSendMessage
      const times = time
        || DateTime.fromISO(new Date().toISOString()).toFormat('dd/LL/yyyy HH:mm')

      return `<span id="${uuid}" class="${dirClass} meessage-box">${message} <span class="message-info">${sendByUsers} ${times} hrs</span> </span>`
    },
    appendMyMessage(message) {
      const appendTo = document.getElementsByClassName('meessage-show')[0]
      appendTo.insertAdjacentHTML('beforeend', message)
    },
    async send_message(e) {
      if (
        (e.key == 'Enter' || e.type == 'click')
        && this.message.send != ''
        && this.message.departament != ''
      ) {
        this.sendMessage(this.message.departament, this.message.send)
        this.message.send = ''
      }
    },
    async sendMessage(departament, message) {
      await buildingsMessages
        .create({
          department_uuid: departament,
          content: message,
        })
        .then(response => {
          this.messageLargeAmountMessage.push(response.data.uuid)
          this.appendMyMessage(
            this.constructMessage(message, 'inside', response.data.uuid),
          )
          this.scrollDown()
        })
        .catch(error => {})
    },

    hasUnreadMessages(item) {
      return (
        item.last_message_read_at === null
        || item.last_message_created_at > item.last_message_read_at
      )
    },
    async loadMessages(departament) {
      await buildingsMessages
        .message({
          params: {
            department_uuid: departament,
            limit: 100,
            page: 0,
          },
        })
        .then(response => {
          let concatHtml = ''

          Object.entries(response.data.data).forEach(entry => {
            const [key, value] = entry
            const dir = this.userSendMessageUuid != value.created_by.uuid
              ? 'outside'
              : 'inside'

            if (
              !this.messageLargeAmountMessage.includes(value.uuid)
              && this.messageLargeAmountMessage.length > 0
            ) {
              concatHtml += this.constructMessage(
                value.content,
                dir,
                value.uuid,
                `${value.created_by.name} ${value.created_by.last_name}`,
                DateTime.fromISO(value.created_at).toFormat('dd/LL/yyyy HH:mm'),
              )
            } else {
              concatHtml += this.constructMessage(
                value.content,
                dir,
                value.uuid,
                `${value.created_by.name} ${value.created_by.last_name}`,
                DateTime.fromISO(value.created_at).toFormat('dd/LL/yyyy HH:mm'),
              )
            }
          })

          this.appendMyMessage(concatHtml)
          this.scrollDown()
        })
        .catch(error => {})
    },
    selectDepartament(item) {
      this.message.departament = item.id
      this.message.departamentName = item.name
      document.getElementById('blur-bg-img').classList.add('blur-bg-img')
      document.getElementsByClassName('meessage-show')[0].innerHTML = ''
      this.loadMessages(item.id)
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1

      this.documents(page)
    },
    async loadDepartaments() {
      await departaments
        .list({
          params: {
            limit: 100,
            page: 0,
          },
        })
        .then(response => {
          this.checkLoadWholesData.push('B')
          this.loadDepartamentsData = []
          Object.entries(response.data.data).forEach(entry => {
            const [key, value] = entry
            this.loadDepartamentsData.push({
              id: value.uuid,
              name: value.nro,
              last_message: '',
              last_message_created_at: value.last_message_created_at,
              last_message_read_at: value.last_message_read_at,
            })
          })
        })
        .catch(error => {
          sweet.ToastMsg('', 'error', 'no se pueden cargar los departamentos')
        })
    },
    async loadLastMessages() {
      this.checkLoadWholesData.push('A')

      buildingsMessages
        .latest({
          params: {
            limit: 100,
            page: 0,
          },
        })
        .then(response => {
          this.items = response.data.data.map(value => ({
            id: value.department.uuid,
            name: value.department.nro,
            last_message: DateTime.fromISO(value.created_at).toFormat(
              'dd-LL-yyyy HH:mm',
            ),
            last_message_created_at: DateTime.fromISO(
              value.created_at,
            ).toSeconds(),
            last_message_read_at: value.read_at
              ? DateTime.fromISO(value.read_at).toSeconds()
              : null,
          }))
          this.existingUuid = this.items.map(item => item.id)
        })
        .catch(error => {
          console.error('Error al cargar los últimos mensajes:', error)
        })
    },
  },
}
</script>
<style></style>
