<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-32"
    >
      <b-row
        align-h="between"
        class="mt-n32"
      >
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb />
        </b-col>
      </b-row>
    </b-col>

    <b-col
      cols="12"
      class="mb-32"
    >
      <b-row
        class="hp-profile-mobile-menu-btn bg-black-0 hp-bg-color-dark-100 rounded py-12 px-8 px-sm-12 mb-16 mx-0"
      >
        <div class="d-inline-block">
          <b-button
            v-b-toggle.sidebar-profile
            variant="none"
            class="border-0 btn-icon-only"
          >
            <i
              class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </div>
      </b-row>

      <b-row
        class="bg-black-0 hp-bg-color-dark-100 rounded pr-16 pr-sm-32 mx-0"
      >
        <b-col class="pl-16 pl-sm-32 p--24 py-sm-32 overflow-hidden">
          <List />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import Breadcrumb from '@/layouts/components/content/breadcrumb/Breadcrumb.vue'
import List from './List.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    Breadcrumb,
    List,
  },
  data() {
    return {
      routeName: '',
      building: {},
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  beforeMount() {},
  mounted() {
    this.getSales()
  },
  methods: {
    getSales() {},
  },
}
</script>
